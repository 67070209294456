/*! main.js | Bulkit | CSS Ninja */

/* ==========================================================================
Core JS file
========================================================================== */

'use strict';

$(document).ready(function($) {

  //2. Lazy loading
  const el = document.querySelectorAll('[data-lazy-load]');
  const observer = lozad(el, {
    loaded: function(el) {
      // Custom implementation on a loaded element
      el.parentNode.classList.add('loaded');
    }
  });

  observer.observe();


  //4. Init Feather icons
  // feather.replace();

  //5. Init Layout
  initNavbar();
  initLandingNavbar();
  initMobileMenu();
  initLandingMobileMenu();

  //6. Components
  // initTabsNav();

  //8. Forms
  initMaterialSelect();

  //10. Demo
  // initScrollspyNav();
  initParallax();
  initBackToTop();

  //11. Utility functions
  initAnchorScroll();
  initScrollReveal();

  //12. Page specific methods
  // initMockup();
  initPricing();
  initTabbedPricing();
  initFreelancerPricing();
  initSwitchPricing();
  initBoxedPricing();
  initOnePagePricing();
  //initBlog();
  initAnimations();
  initParticles();
  initAnimatedSvg();
  // initMapBox();

  $('form[name="wl_sub_form"]').submit(function(e) {
    e.preventDefault();
    console.log('waiting list for beta -> form submitted');
    const email = $('#wl_sub_email_input').val();
    // alert(email);
    console.log('sub button has been clicked', email);
    $.ajax({
      type: 'PUT',
      url: 'https://api.sendgrid.com/v3/marketing/contacts',
      headers: {
        'Authorization': 'Bearer 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',
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ 'list_ids': ['e68da693-f2f1-488a-9ede-4f8af2975f85'], 'contacts': [{ 'email': email }] }),
      success: function(result) {
        $('#wl_sub_succ').removeClass('is-hidden');
        $('#wl_sub_email_input').val('');
      },
      error: function(result) {
        console.error('Error: ' + result);
        // alert('error');
      }
    });
  });

  $('form[name="join_beta_sub_form"]').submit(function(e) {
    e.preventDefault();
    console.log('waiting list for beta -> form submitted');
    const first_name = $('#join_beta_firstname').val();
    const last_name = $('#join_beta_lastname').val();
    const email = $('#join_beta_email').val();
    const phone_number = $('#join_beta_tel').val();
    // alert(email);
    console.log('sub button has been clicked', email);
    $.ajax({
      type: 'PUT',
      url: 'https://api.sendgrid.com/v3/marketing/contacts',
      headers: {
        'Authorization': 'Bearer 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',
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({
        'list_ids': ['e68da693-f2f1-488a-9ede-4f8af2975f85'],
        'contacts': [{ first_name, last_name, email, phone_number }]
      }),
      success: function(result) {
        $('#join_beta_sub_succ').removeClass('is-hidden');
        $('#join_beta_firstname').val('');
        $('#join_beta_lastname').val('');
        $('#join_beta_email').val('');
        $('#join_beta_tel').val('');
      },
      error: function(result) {
        console.error('Error: ' + result);
        // alert('error');
      }
    });
  });

  $('form[name="sub_form"]').submit(function(e) {
    e.preventDefault();
    console.log('form submitted');
    const email = $('#sub_email_input').val();
    // alert(email);
    console.log('sub button has been clicked', email);
    $.ajax({
      type: 'PUT',
      url: 'https://api.sendgrid.com/v3/marketing/contacts',
      headers: {
        'Authorization': 'Bearer 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',
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ 'list_ids': ['396fcfce-4471-42c6-bc57-9c1bceddfe49'], 'contacts': [{ 'email': email }] }),
      success: function(result) {
        $('#sub_succ').removeClass('is-hidden');
        $('#sub_email_input').val('');
      },
      error: function(result) {
        console.error('Error: ' + result);
        // alert('error');
      }
    });
  });

  $('form[name="sub_form_mobile"]').submit(function(e) {
    e.preventDefault();
    console.log('mobile form submitted');
    const email = $('#sub_email_input_mobile').val();
    // alert(email);
    console.log('sub button has been clicked', email);
    $.ajax({
      type: 'PUT',
      url: 'https://api.sendgrid.com/v3/marketing/contacts',
      headers: {
        'Authorization': 'Bearer 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',
        'Content-Type': 'application/json'
      },
      data: JSON.stringify({ 'list_ids': ['396fcfce-4471-42c6-bc57-9c1bceddfe49'], 'contacts': [{ 'email': email }] }),
      success: function(result) {
        $('#sub_succ').removeClass('is-hidden');
        $('#sub_email_input_mobile').val('');
      },
      error: function(result) {
        console.error('Error: ' + result);
        // alert('error');
      }
    });
  });

  $(document).on('click', '#sub_succ_btn', function(e) {
    $('#sub_succ').addClass('is-hidden');
  });

  $(document).on('click', '#wl_sub_succ_btn', function(e) {
    $('#wl_sub_succ').addClass('is-hidden');
  });

  $(document).on('click', '#join_beta_sub_succ_btn', function(e) {
    $('#join_beta_sub_succ').addClass('is-hidden');
  });
});
