/* ==========================================================================
Animations
========================================================================== */

"use strict";

function initAnimations() {
  if ($("#features-1").length) {
    var waypoint = new Waypoint({
      element: document.getElementById("features-1"),
      handler: function (direction) {
        $(".shadow-side-image").addClass("is-active");
      },
      offset: 150,
    });
  }

  var primaryCircle = document.querySelector(".primary-circle");
  var primaryBoldCircle = document.querySelector(".primary-bold-circle");
  var accentCircle = document.querySelector(".accent-circle");

  if ($(".primary-circle, .primary-bold-circle, .accent-circle").length) {
    anime({
      targets: [primaryCircle, primaryBoldCircle, accentCircle],
      translateY: [
        {
          value: 800,
          duration: 500,
        },
      ],
    });
  }

  if ($("#features-2").length) {
    var waypoint2 = new Waypoint({
      element: document.getElementById("features-2"),
      handler: function (direction) {
        anime({
          targets: primaryCircle,
          translateY: [
            {
              value: 0,
              duration: 1100,
            },
          ],
        });

        anime({
          targets: primaryBoldCircle,
          translateY: [
            {
              value: 0,
              duration: 1100,
              delay: 200,
            },
          ],
        });

        anime({
          targets: accentCircle,
          translateY: [
            {
              value: 0,
              duration: 1100,
              delay: 400,
            },
          ],
        });
      },
      offset: 150,
    });
  }

  if ($("#features-3").length) {
    var waypoint3 = new Waypoint({
      element: document.getElementById("features-3"),
      handler: function (direction) {
        $("#features-3 .side-image").removeClass("is-pulled");
      },
      offset: 150,
    });
  }
}

function initAnimatedSvg() {
  if ($("#chat-widget-ui, #chat-ui").length) {
    new Vivus("chat-widget-ui", {
      duration: 150,
      file: "assets/img/graphics/compositions/chat-widget-core.svg",
    });
    new Vivus("chat-ui", {
      duration: 150,
      file: "assets/img/graphics/compositions/chat-profile-core.svg",
    });
  }
}

function initParticles() {
  var particles = document.getElementById("particles");
  var border = ["50%", "0%"];
  var colors = ["#FF6B6B", "#FFE66D", "#4472CA"];

  if ($("#particles").length) {
    function createParticle(event) {
      var x = event.clientX;
      var y = event.clientY;
      var color = Math.floor(Math.random() * 3);

      var div = document.createElement("div");
      div.style.position = "absolute";
      div.style.marginLeft = x + "px";
      div.style.marginTop = y + "px";
      div.style.width = "10px";
      div.style.borderTop = "5px solid transparent";
      div.style.borderRight = "5px solid transparent";
      div.style.borderLeft = "5px solid transparent";
      div.style.borderBottom = "10px solid " + colors[color];
      div.style.animation = "move 5s ease-in infinite";
      particles.appendChild(div);
      setTimeout(function () {
        div.remove();
      }, 5000);
    }

    function getParticles() {
      var np = document.documentElement.clientWidth / 40;
      particles.innerHTML = "";
      for (var i = 0; i < np; i++) {
        var w = document.documentElement.clientWidth;
        var h = document.documentElement.clientHeight;
        var rndw = Math.floor(Math.random() * w) + 1;
        var rndh = Math.floor(Math.random() * h) + 1;
        var widthpt = Math.floor(Math.random() * 8) + 5;
        var opty = Math.floor(Math.random() * 4) + 1;
        var anima = Math.floor(Math.random() * 12) + 8;
        var bdr = Math.floor(Math.random() * 2);
        var color = Math.floor(Math.random() * 3);

        var div = document.createElement("div");
        div.style.position = "absolute";
        div.style.marginLeft = rndw + "px";
        div.style.marginTop = rndh + "px";
        div.style.width = widthpt + "px";
        div.style.height = widthpt + "px";
        div.style.opacity = opty;
        div.style.backgroundColor = colors[color];
        div.style.borderRadius = border[bdr];
        div.style.animation = "move " + anima + "s ease-in infinite";
        particles.appendChild(div);
      }
    }

    function main(event) {
      getParticles();
      particles.addEventListener("click", createParticle);
    }

    window.addEventListener("resize", main);
    window.addEventListener("load", main);
  }
}
