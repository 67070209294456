/* ==========================================================================
Environment
========================================================================== */

"use strict";

var env = "";

/* ==========================================================================
Active Theme
========================================================================== */

var activeTheme = "core";

/* ==========================================================================
Theme Colors
========================================================================== */

var themes = {
  core: {
    primary: "#8c8cf9",
    secondary: "#7F00FF",
    accent: "#ad5cff",
  },
  purple: {
    primary: "#837FCB",
    secondary: "#5551a8",
    accent: "#6A3144",
  },
  teal: {
    primary: "#00D1B2",
    secondary: "#7F00FF",
    accent: "#536dfe",
  },
  green: {
    primary: "#00b289",
    secondary: "#00b289",
    accent: "#00b289",
  },
  red: {
    primary: "#ff5555",
    secondary: "#ff3131",
    accent: "#1ddba5",
  },
};
